import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  EditBasicInquiryInfoDialogComponent,
  EditInquiryBaseDataFields,
} from '../../../shared/dialogs/edit-basic-inquiry-info-dialog/edit-basic-inquiry-info-dialog.component';
import { InquiryDashboardSummary } from '../../../model/inquiry/inquiry-dashboard-summary';

@Component({
  selector: 'app-inquiry-details-customer-data',
  templateUrl: './inquiry-details-customer-data.component.html',
  styleUrls: ['./inquiry-details-customer-data.component.scss'],
})
export class InquiryDetailsCustomerDataComponent implements OnDestroy {
  @Input() refresh: Subject<void>;
  @Input() inquiry: InquiryDashboardSummary;
  @Output() inquiryUpdated = new EventEmitter<void>();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private readonly dialog: MatDialog) {}

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }

  protected editContactDetails() {
    this.dialog
      .open(EditBasicInquiryInfoDialogComponent, {
        data: {
          inquiryId: this.inquiry.inquiryIdentifier,
          phoneNumber: this.inquiry.phoneNumber,
          emailVerified: this.inquiry.email,
          firstName: this.inquiry.firstName,
          lastName: this.inquiry.lastName,
        } as EditInquiryBaseDataFields,
        maxWidth: '100%',
        maxHeight: '100vh',
      })
      .afterClosed()
      .subscribe((shouldReload) => {
        if (shouldReload) {
          this.inquiryUpdated.emit();
        }
      });
  }
}
