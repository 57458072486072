<div class="container" *ngIf="inquiry$ | async; let inquiry">
  <div>
    <app-inquiry-details-header
      [inquiry]="inquiry"
      (callCancelled)="onCallCancelled()"
    ></app-inquiry-details-header>
  </div>
  <div
    class="page-content"
    *ngIf="inquiry?.manuallyDeletedAt || inquiry?.autoDeletedAt"
  >
    <app-inquiry-details-deletion-data
      class="section-container"
      [inquiry]="inquiry"
    ></app-inquiry-details-deletion-data>
  </div>

  <div class="page-content">
    <app-inquiry-details-customer-data
      class="section-container"
      (inquiryUpdated)="customerDataUpdated()"
      [inquiry]="inquiry"
      [refresh]="refresh$"
    >
    </app-inquiry-details-customer-data>

    <app-inquiry-details-savings
      *ngIf="inquiry?.inquiryIdentifier"
      [inquiryId]="inquiry.inquiryIdentifier"
      class="section-container"
    >
    </app-inquiry-details-savings>
  </div>
  <div class="page-content">
    <div class="inquiry-details-chips-container section-container">
      <ng-container
        *ngFor="let inquiryDetailsComponentChip of inquiryDetailsComponentChips"
      >
        <app-tab-chip
          (chipSelectedEmitter)="
            inquiryInformationComponentChipSelected($event)
          "
          [tabChipData]="inquiryDetailsComponentChip"
        ></app-tab-chip>
      </ng-container>
    </div>

    <div
      *ngIf="inquiryDetailsTabToShow === InquiryDetailsTab.CallData"
      class="section-container"
    >
      <app-inquiry-details-call-data
        [inquiryId]="inquiryId"
        [refresh]="refresh$"
      >
      </app-inquiry-details-call-data>
    </div>

    <div
      *ngIf="inquiryDetailsTabToShow === InquiryDetailsTab.AfterCallAttachments"
      class="section-container"
    >
      <app-inquiry-details-attachments
        [inquiryId]="inquiryId"
        [refresh]="refresh$"
      >
      </app-inquiry-details-attachments>
    </div>

    <div
      *ngIf="inquiryDetailsTabToShow === InquiryDetailsTab.Chat"
      class="section-container"
    >
      <app-inquiry-details-chat [inquiryId]="inquiryId" [refresh]="refresh$">
      </app-inquiry-details-chat>
    </div>

    <div
      *ngIf="inquiryDetailsTabToShow === InquiryDetailsTab.Report"
      class="section-container"
    >
      <app-inquiry-details-report [inquiryId]="inquiryId" [refresh]="refresh$">
      </app-inquiry-details-report>
    </div>

    <div
      *ngIf="inquiryDetailsTabToShow === InquiryDetailsTab.Timetable"
      class="section-container"
    >
      <app-inquiry-details-timeline
        [inquiryId]="inquiryId"
        [refresh]="refresh$"
      >
      </app-inquiry-details-timeline>
    </div>
  </div>
</div>
