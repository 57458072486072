import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NotificationBarService } from './services/notification-bar.service';
import { isScreenWithoutMenus } from './menu/menu-visibility-helper';
import { ActivatedRoute } from '@angular/router';
import { BrandingDataService } from 'aidar-branding';
import { AuthenticationService } from './authentication/authentication.service';
import { SignalingConnectionService } from './services/signaling/signaling-connection.service';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [BrandingDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  protected isLoading = signal(true);
  notificationBarVisible = false;
  menubarVisible = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly signalingConnectionService: SignalingConnectionService,
    private readonly titleService: Title,
    protected readonly notificationBarService: NotificationBarService,
    private readonly route: ActivatedRoute,
    private readonly brandingService: BrandingDataService,
    readonly iconRegistry: MatIconRegistry,
    readonly domSanitizer: DomSanitizer,
  ) {
    this.brandingService.applyBranding().subscribe({
      next: () => {
        this.isLoading.set(false);
      },
      error: () => {
        this.isLoading.set(false);
      },
    });

    this.titleService.setTitle($localize`blankmile`);
    iconRegistry.addSvgIcon(
      'animated_images',
      domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/animated_images.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'stylus_laser_pointer',
      domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/stylus_laser_pointer.svg',
      ),
    );
  }

  async ngOnInit() {
    this.authService.getUser().subscribe((user) => {
      if (user) {
        this.signalingConnectionService.connect(user.profile.sub);
      }
    });
  }

  ngOnDestroy() {
    this.signalingConnectionService.disconnect();
  }

  activatedComponentChanged(event: unknown) {
    this.notificationBarVisible = !isScreenWithoutMenus(event);
    this.menubarVisible = !isScreenWithoutMenus(event);
    if (this.route.snapshot.queryParams['hideMenubar'] === 'true') {
      this.menubarVisible = false;
    }
  }
}
