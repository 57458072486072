import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-salesforce-canvas-callback',
  standalone: true,
  imports: [],
  templateUrl: './salesforce-canvas-callback.component.html',
  styleUrl: './salesforce-canvas-callback.component.scss',
})
export class SalesforceCanvasCallbackComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['code']) {
        console.log(params['code']);
        this.router.navigate(['scheduling']);
      }
    });
  }
}
