import { VideoAppointmentComponent } from '../video-appointment/video-appointment.component';
import { SignupInvitationLandingComponent } from '../signup/signup-invitation-landing/signup-invitation-landing.component';
import { SetupPlatformOverviewComponent } from '../setup/setup-platform-overview/setup-platform-overview.component';
import { LoginComponent } from '../authentication/login/login.component';
import { AuthCallbackComponent } from '../authentication/auth-callback/auth-callback.component';
import { ValidateEmailComponent } from '../employee/verify-email/validate-email.component';

export function isScreenWithoutMenus(event: unknown): boolean {
  return (
    event instanceof VideoAppointmentComponent ||
    event instanceof SignupInvitationLandingComponent ||
    event instanceof SetupPlatformOverviewComponent ||
    event instanceof LoginComponent ||
    event instanceof AuthCallbackComponent ||
    event instanceof ValidateEmailComponent
  );
}
