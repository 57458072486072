import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalesforceTenantResolverService } from '../../services/api/integrations/salesforce-tenant-resolver.service';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-salesforce-canvas',
  standalone: true,
  imports: [MatButton],
  templateUrl: './salesforce-canvas.component.html',
  styleUrl: './salesforce-canvas.component.scss',
  providers: [SalesforceTenantResolverService],
})
export class SalesforceCanvasComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly resolver: SalesforceTenantResolverService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['signed_request']) {
        this.handleSignedRequest(params['signed_request']);
      }
    });
  }

  private handleSignedRequest(signedRequest: string) {
    // TODO: Validate signature
    const [signature, payload] = signedRequest.split('.');
    console.log(`Signature: ${signature}`);
    const decodedPayload = JSON.parse(atob(payload));

    // Extract Salesforce org ID and user info
    const orgId = decodedPayload.context.organization.id;
    const userId = decodedPayload.context.user.userId;

    this.resolver.resolveTenant(orgId, userId).subscribe((tenant) => {
      if (tenant) {
        this.redirectToKeycloak(tenant);
      }
    });
  }

  redirectToKeycloak(realm: string) {
    const clientId = 'salesforce-canvas';
    const redirectUri = encodeURIComponent(
      window.location.origin + '/callback',
    );
    window.location.href = `https://auth.blankmile.com/realms/${realm}/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
  }

  openOtherSite() {
    window.open('https://demo.blankmile.com', '_blank', 'noopener');
  }
}
