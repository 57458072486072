import { Injectable } from '@angular/core';
import { RealmConfiguration } from '../../environments/constants';

@Injectable({ providedIn: 'root' })
export class RealmService {
  private static readonly DEFAULT_SUBDOMAIN: string =
    RealmConfiguration.defaultRealm;

  private readonly realm: string;

  constructor() {
    this.realm = this.getSubdomain();
  }

  public getRealm(): string {
    return this.realm;
  }

  public getClientRootForRealm(clientRootUrl: string): string {
    return clientRootUrl.replace('*', this.getRealm());
  }

  private getSubdomain(): string {
    let subdomain: string;
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 || !Number.isNaN(+domain.split('.')[0])) {
      subdomain = RealmService.DEFAULT_SUBDOMAIN;
    } else {
      subdomain = domain.split('.')[0];
    }

    return subdomain;
  }
}
