import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { StorageKey, LocalStorageService } from './local-storage.service';

export type Devices = MediaDeviceInfo[];

export enum DeviceType {
  AUDIO_INPUT = 'audioinput',
  AUDIO_OUTPUT = 'audiooutput',
  VIDEO_INPUT = 'videoinput',
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  public settingsChanged$ = new Subject<SettingUpdate>();
  public outputSelectionPossible = true;

  private readonly deviceBroadcast = new ReplaySubject<Devices>(1);
  private _devices: Devices;

  devicesUpdated$: Observable<Devices> = this.deviceBroadcast.asObservable();

  constructor(private readonly storageService: LocalStorageService) {
    this.outputSelectionPossible = this.checkIfOutputSelectionIsPossible();
    this.devicesUpdated$.subscribe(() => {
      this.checkIfSelectedDevicesStillAvailable();
    });
  }

  public publishDeviceOptions(devices: Devices) {
    this._devices = devices;
    this.deviceBroadcast.next(devices);
  }

  private checkIfSelectedDevicesStillAvailable() {
    const currentAudioOutput = this.getSelectedAudioOutputDevice();
    const currentVideoInput = this.getSelectedAudioDeviceId();
    const currentAudioInput = this.getSelectedAudioDeviceId();

    if (!this._devices) return;

    if (this._devices.findIndex((x) => x.deviceId === currentAudioInput) < 0) {
      this.storageService.remove('audioInputId');
    }
    if (this._devices.findIndex((x) => x.deviceId === currentVideoInput) < 0) {
      this.storageService.remove('videoInputId');
    }
    if (this._devices.findIndex((x) => x.deviceId === currentAudioOutput) < 0) {
      this.storageService.remove('audioOutputId');
    }
  }

  public changeSelectedDevice(selection: MediaDeviceInfo): void {
    switch (selection.kind) {
      case 'audioinput':
        this.storeDeviceSelection('audioInputId', selection.deviceId);
        this.settingsChanged$.next({
          updateType: SettingUpdateType.AudioInput,
          deviceId: selection.deviceId,
        });
        break;
      case 'videoinput':
        this.storeDeviceSelection('videoInputId', selection.deviceId);
        this.settingsChanged$.next({
          updateType: SettingUpdateType.VideoInput,
          deviceId: selection.deviceId,
        });
        break;
      case 'audiooutput':
        this.storeDeviceSelection('audioOutputId', selection.deviceId);
        this.settingsChanged$.next({
          updateType: SettingUpdateType.AudioOutput,
          deviceId: selection.deviceId,
        });
        break;
    }
  }

  public checkIfOutputSelectionIsPossible(
    elementToTest?: SinkedHTMLMediaElement,
  ) {
    if (!elementToTest) {
      elementToTest = document.createElement('audio') as SinkedHTMLMediaElement;
    }
    return typeof elementToTest.setSinkId == 'function';
  }

  public getSelectedAudioOutputDevice(): string | undefined {
    return this.storageService.get('audioOutputId');
  }

  public getSelectedAudioDeviceId(): string | undefined {
    return this.storageService.get('audioInputId');
  }

  public getSelectedVideoDeviceId(): string | undefined {
    return this.storageService.get('videoInputId');
  }

  private storeDeviceSelection(key: StorageKey, deviceId: string) {
    this.storageService.set(key, deviceId);
  }

  public getMicState(): MicState {
    const value = this.storageService.get('micState');
    return MicState[value as keyof typeof MicState];
  }

  public setMicState(state: MicState) {
    return this.storageService.set('micState', MicState[state]);
  }

  public getCamState(): CamState {
    const value = this.storageService.get('camState');
    return CamState[value as keyof typeof CamState];
  }

  public setCamState(state: CamState) {
    return this.storageService.set('camState', CamState[state]);
  }

  public setAudioDevice(deviceId: string) {
    this.storeDeviceSelection('audioInputId', deviceId);
  }

  public setVideoDevice(deviceId: string) {
    this.storeDeviceSelection('videoInputId', deviceId);
  }
}

export enum MicState {
  Muted,
  Unmuted,
}

export enum CamState {
  On,
  Off,
}

export interface SinkedHTMLMediaElement extends HTMLMediaElement {
  setSinkId(id: string): Promise<void>;
}

export interface SettingUpdate {
  updateType: SettingUpdateType;
  deviceId: string;
}

export enum SettingUpdateType {
  AudioInput,
  AudioOutput,
  VideoInput,
}
