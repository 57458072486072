import { Injectable } from '@angular/core';
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { environment } from '../../environments/environment';
import { InitiatedActionService } from './initiated-action.service';
import { RoleService } from './role.service';
import { RealmService } from './realm.service';
import { from, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private readonly userManager: UserManager;

  constructor(
    private readonly initiatedActionService: InitiatedActionService,
    private readonly realmService: RealmService,
    private readonly roleService: RoleService,
  ) {
    this.userManager = this.getUserManager();
    this.registerUserManagerCallbacks();
  }

  public getUser(): Observable<User> {
    return from(this.userManager.getUser()).pipe(
      tap((user) => this.roleService.updateRoles(user)),
    );
  }

  public async getUserIdentifier(): Promise<string> {
    const user = await this.userManager.getUser();
    return user.profile.sub;
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public async loginInsecure(
    username: string,
    password: string,
  ): Promise<void> {
    await this.userManager.signinResourceOwnerCredentials({
      username,
      password,
      skipUserInfo: false,
    });
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public resetPassword(redirectPath: string): void {
    this.initiatedActionService.resetPassword(redirectPath);
  }

  public configureTotp(redirectPath: string): void {
    this.initiatedActionService.configureTotp(redirectPath);
  }

  private getUserManager(): UserManager {
    const realm = this.realmService.getRealm();
    const clientRoot = this.realmService.getClientRootForRealm(
      environment.auth.clientRootTemplate,
    );
    const settings = {
      authority: `${environment.auth.authority}/realms/${realm}`,
      client_id: environment.auth.clientId,
      redirect_uri: `${clientRoot}/auth-callback`,
      silent_redirect_uri: `${clientRoot}/silent-callback.html`,
      post_logout_redirect_uri: `${clientRoot}`,
      response_type: 'code',
      scope: environment.auth.clientScope,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      monitorSession: true,
    };
    return new UserManager(settings);
  }

  private registerUserManagerCallbacks() {
    this.userManager.events.addUserLoaded((user: User) => {
      this.roleService.updateRoles(user);
    });

    this.userManager.events.addAccessTokenExpired(
      async () => await this.logout(),
    );

    this.userManager.events.addUserSignedOut(async () => await this.logout());
  }

  async authCallback() {
    await this.userManager.signinCallback();
  }
}
