export class TabChipData {
  index: number;
  identifier: TabChipIdentifier;
  name: string;
  isSelected: boolean;

  constructor(
    index: number,
    identifier: TabChipIdentifier,
    name: string,
    isSelected: boolean,
  ) {
    this.index = index;
    this.identifier = identifier;
    this.name = name;
    this.isSelected = isSelected;
  }
}

export enum TabChipIdentifier {
  Files = 'files',
  Attachments = 'attachments',
  Chat = 'chat',
  Report = 'report',
  History = 'history',
}
