import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private readonly router: Router) {}

  public navigateToVideoAppointment(
    inquiryId: string,
    invitationAlreadySent = true,
  ): void {
    this.router.navigate(['/video-appointment'], {
      queryParams: {
        inquiryId: inquiryId,
        ias: invitationAlreadySent,
      },
      queryParamsHandling: 'merge',
    });
  }

  public navigateToInquiryDetails(
    inquiryId: string,
    replaceUrl?: boolean,
    section?: string,
  ): void {
    this.router.navigate(['/scheduling/details'], {
      queryParams: { inquiryId: inquiryId, section: section },
      replaceUrl: replaceUrl,
    });
  }

  public navigateToAttachment(attachmentId: string): void {
    this.router.navigate(['/scheduling/navigate-to-attachment'], {
      queryParams: { attachmentId: attachmentId },
    });
  }
}
