import { Component, OnInit } from '@angular/core';
import { AttachmentService } from '../../../../services/api/attachment.service';
import { first } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../../services/util/navigation.service';
import { TabChipIdentifier } from '../../../../shared/tab-chip/inquiry-detail-component-chip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  ViewAttachmentData,
  ViewAttachmentDialogComponent,
} from '../../../dialogs/view-attachment-dialog/view-attachment-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-navigate-to-attachment',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './navigate-to-attachment.component.html',
  styleUrl: './navigate-to-attachment.component.scss',
})
export class NavigateToAttachmentComponent implements OnInit {
  private attachmentId: string;
  constructor(
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly attachmentService: AttachmentService,
  ) {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      this.attachmentId = params['attachmentId'];
    });
  }

  ngOnInit(): void {
    this.attachmentService
      .getAttachment(this.attachmentId)
      .subscribe((attachment) => {
        this.navigationService.navigateToInquiryDetails(
          attachment.inquiryIdentifier,
          true,
          TabChipIdentifier.Attachments.valueOf(),
        );
        const data = {
          currentAttachment: attachment,
          allAttachments: [attachment],
        } as ViewAttachmentData;
        this.dialog.open(ViewAttachmentDialogComponent, {
          data: data,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          panelClass: 'fullscreen-dialog',
        });
      });
  }
}
