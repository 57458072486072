import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { AuthenticationService } from './authentication.service';

export const authenticationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot, // eslint-disable-line @typescript-eslint/no-unused-vars
  state: RouterStateSnapshot, // eslint-disable-line @typescript-eslint/no-unused-vars
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthenticationService);

  return authService.getUser().pipe(
    switchMap((user) => {
      return of(!!user);
    }),
  );
};
