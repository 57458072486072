import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Notification } from '../../../model/notification-center/notification';
import { NotificationType } from '../../../model/notification-center/notification-type';
import { NotificationCenterService } from '../../../services/api/notification-center.service';
import { BehaviorSubject } from 'rxjs';
import { UpdateNotificationRequest } from '../../../model/notification-center/request/update-notification-request';
import { ReferenceType } from '../../../model/notification-center/reference-type';
import { Router } from '@angular/router';
import { NavigationService } from '../../../services/util/navigation.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  providers: [NotificationCenterService],
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: Notification;
  @Output() notificationRead = new EventEmitter<Notification>();

  private readonly markingAsUnread$ = new BehaviorSubject(false);
  protected notificationTitle: string;
  protected notificationText: string;
  protected markAsReadTooltip = $localize`Als gelesen markieren.`;
  protected notificationIcon = 'circle';

  constructor(
    private readonly notificationCenterService: NotificationCenterService,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.notificationTitle = this.buildNotificationTitle(this.notification);
    this.notificationText = this.buildNotificationText(this.notification);
  }

  private buildNotificationText(notification: Notification) {
    if (notification.type === NotificationType.AttachmentCreated) {
      return $localize`Ein neuer Anhang ist verfügbar.`;
    }

    return $localize`Unbekannte Benachrichtigung.`;
  }

  private buildNotificationTitle(notification: Notification) {
    if (notification.type === NotificationType.AttachmentCreated) {
      return $localize`Neuer Anhang`;
    }

    return $localize`Benachrichtigung.`;
  }

  onNotificationClick() {
    if (this.markingAsUnread$.value === false) {
      this.redirectToReference();
      this.onNotificationReadClick();
    }
  }

  onNotificationReadClick(event?: Event) {
    this.markingAsUnread$.next(true);
    this.notificationIcon = 'check_circle';
    const request = {
      read: true,
      notificationId: this.notification.id,
    } as UpdateNotificationRequest;
    this.notificationCenterService.updateNotification(request).subscribe({
      next: (res) => {
        this.notificationRead.next(res);
      },
      error: () => {
        this.notificationIcon = 'circle';
        this.markingAsUnread$.next(false);
      },
    });
    event?.stopPropagation();
  }

  private redirectToReference() {
    if (this.notification.referenceId) {
      switch (this.notification.referenceType) {
        case ReferenceType.Attachment:
          this.navigationService.navigateToAttachment(
            this.notification.referenceId,
          );
          return;
        case ReferenceType.Inquiry:
          this.navigationService.navigateToInquiryDetails(
            this.notification.referenceId,
          );
          return;
        default:
          return;
      }
    }
  }
}
