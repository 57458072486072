import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Roles } from './authentication/roles';
import { ProfileComponent } from './profile/profile.component';
import { SignupInvitationLandingComponent } from './signup/signup-invitation-landing/signup-invitation-landing.component';
import { SetupPlatformOverviewComponent } from './setup/setup-platform-overview/setup-platform-overview.component';
import { LoginComponent } from './authentication/login/login.component';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';
import { ValidateEmailComponent } from './employee/verify-email/validate-email.component';
import { SalesforceCanvasComponent } from './integrations/salesforce-canvas/salesforce-canvas.component';
import { SalesforceCanvasCallbackComponent } from './integrations/salesforce-canvas-callback/salesforce-canvas-callback.component';
import { authenticationGuard } from './authentication/authentication.guard';

export const videoAppointmentRoute = 'video-appointment';

export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'employee',
    loadChildren: () =>
      import('./employee/employee.module').then((m) => m.EmployeeModule),
    canActivate: [authenticationGuard],
    data: { roles: [Roles.Manager] },
  },
  {
    path: 'validate-email',
    component: ValidateEmailComponent,
  },
  {
    path: 'scheduling',
    loadChildren: () =>
      import('./scheduling/scheduling.module').then((m) => m.SchedulingModule),
    canActivate: [authenticationGuard],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./company/company.module').then((m) => m.CompanyModule),
    canActivate: [authenticationGuard],
  },
  {
    path: videoAppointmentRoute,
    loadChildren: () =>
      import('./video-appointment/video-appointment.module').then(
        (m) => m.VideoAppointmentModule,
      ),
    canActivate: [authenticationGuard],
  },
  {
    path: 'signup',
    component: SignupInvitationLandingComponent,
  },
  {
    path: 'setup',
    component: SetupPlatformOverviewComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'integrations/salesforce',
    component: SalesforceCanvasComponent,
    children: [
      { path: 'auth-callback', component: SalesforceCanvasCallbackComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
