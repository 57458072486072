import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  StorageKey,
  LocalStorageService,
} from '../services/local-storage.service';

class IdGenerator {
  protected static id = 0;

  static getNext() {
    return ++IdGenerator.id;
  }
}

@Component({
  selector: 'app-device-select',
  templateUrl: './device-select.component.html',
  styleUrl: './device-select.component.scss',
})
export class DeviceSelectComponent {
  id: string;
  @Input() selectedId: string;
  @Input() label: string;
  @Input() iconName: string;
  @Input() kind: MediaDeviceKind;
  @Input() key: StorageKey;
  @Input() disabled = false;
  @Output() settingsChanged = new EventEmitter<MediaDeviceInfo>();
  @Input()
  devices: MediaDeviceInfo[] = [];

  constructor(private readonly storageService: LocalStorageService) {
    this.id = `device-select-${IdGenerator.getNext()}`;
  }

  onSettingsChanged(deviceId: string) {
    this.selectedId = deviceId;
    this.settingsChanged.emit(
      this.devices.find((d) => d.deviceId === deviceId),
    );
  }
}
