import { Employee } from '../../model/employee/employee';

export class DashboardInformationService {
  public static calculateCallDuration(start: string, end: string): string {
    if (!start || !end) return '-:-';

    const refDate = end ? new Date(end) : new Date();
    const elapsedTime = refDate.getTime() - new Date(start).getTime();

    let seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    return (
      (minutes < 10 ? '0' : '') +
      minutes.toLocaleString() +
      ':' +
      (seconds < 10 ? '0' : '') +
      seconds.toLocaleString()
    );
  }

  public static getEmployeeDisplayName(employee: Employee) {
    if (employee === null) {
      return $localize`Nicht zugewiesen`;
    } else if (employee.firstName === null && employee.lastName === null) {
      return employee.email;
    } else if (employee.lastName === null) {
      return `${employee.firstName}`;
    } else if (employee.firstName === null) {
      return `${employee.lastName}`;
    }

    return `${employee.firstName} ${employee.lastName}`;
  }
}
