import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InquiryState } from 'projects/helper-client/src/api/gen';
import { BehaviorSubject, first, Subject } from 'rxjs';
import {
  TabChipData,
  TabChipIdentifier,
} from '../../shared/tab-chip/inquiry-detail-component-chip';
import { InquiryDashboardSummary } from '../../model/inquiry/inquiry-dashboard-summary';
import { InquiryDashboardService } from '../../services/api/inquiry-dashboard.service';

@Component({
  selector: 'app-inquiry-details',
  templateUrl: './inquiry-details.component.html',
  styleUrls: ['./inquiry-details.component.scss'],
})
export class InquiryDetailsComponent implements OnInit, OnDestroy {
  InquiryState = InquiryState;
  protected refresh$ = new Subject<void>();
  protected inquiryDetailsTabToShow = InquiryDetailsTab.CallData;
  protected InquiryDetailsTab = InquiryDetailsTab;
  protected inquiryId: string;
  protected inquiry$: BehaviorSubject<InquiryDashboardSummary>;
  protected inquiryDetailsComponentChips: TabChipData[] = [
    new TabChipData(
      0,
      TabChipIdentifier.Files,
      $localize`Daten aus dem Videocall`,
      true,
    ),
    new TabChipData(
      1,
      TabChipIdentifier.Attachments,
      $localize`Anhänge`,
      false,
    ),
    new TabChipData(2, TabChipIdentifier.Chat, $localize`Chat`, false),
    new TabChipData(3, TabChipIdentifier.Report, $localize`PDF Report`, false),
    new TabChipData(4, TabChipIdentifier.History, $localize`Verlauf`, false),
  ];
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dashboardService: InquiryDashboardService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      this.inquiryId = params['inquiryId'];
      this.activateSection(params['section']);
      this.refreshAll();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  inquiryInformationComponentChipSelected(index: number) {
    this.inquiryDetailsComponentChips.map((inquiryDetailsComponent) => {
      if (inquiryDetailsComponent.index == index) {
        inquiryDetailsComponent.isSelected = true;
        this.inquiryDetailsTabToShow = index;
      } else {
        inquiryDetailsComponent.isSelected = false;
      }
    });
  }

  customerDataUpdated() {
    this.refreshInquiry();
  }

  private refreshInquiry(): void {
    this.dashboardService
      .getInquiryDetails(this.inquiryId)
      .pipe(first())
      .subscribe((data) => {
        if (this.inquiry$) {
          this.inquiry$.next(data);
        } else {
          this.inquiry$ = new BehaviorSubject(data);
        }
        this.ref.markForCheck();
      });
  }

  private refreshAll() {
    this.refreshInquiry();
    this.refresh$.next();
  }

  private activateSection(section: string) {
    const idx = this.inquiryDetailsComponentChips.findIndex(
      (x) => x.identifier.valueOf() === section,
    );

    if (idx >= 0) {
      this.inquiryInformationComponentChipSelected(idx);
    }
  }

  protected onCallCancelled() {
    this.refreshAll();
  }
}

export enum InquiryDetailsTab {
  CallData = 0,
  AfterCallAttachments = 1,
  Chat = 2,
  Report = 3,
  Timetable = 4,
}
