<div class="inquiry-infos-container">
  <div class="inquiry-link-container">
    <h4 class="inquiry-link-heading" i18n>Einladungslink</h4>
    <app-copy-inquiry-link [inquiry$]="inquiry$"></app-copy-inquiry-link>
  </div>

  <div class="inquiry-info-container">
    <h4 class="inquiry-link-heading" i18n>Details zum Termin</h4>
    @if (isLoading$()) {
      <div class="loading-spinner">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
    } @else {
      @if (inquiry$ | async; as inquiry) {
        <div class="detail-data">
          <app-inquiry-info-item [label]="nameLbl" [value]="nameValue()" />
          <app-inquiry-info-item [label]="emailLbl" [value]="inquiry.email" />
          <app-inquiry-info-item
            [label]="locationLbl"
            [value]="location()"
            i18n-actionName
            actionName="Ansehen"
            [actionEnabled]="location() !== null"
            (actionClicked)="viewLocation()"
          />
        </div>
        <div class="inquiry-info-actions">
          <button
            [disabled]="isLoading$()"
            (click)="editContactDetails()"
            color="primary"
            mat-flat-button
            class="inquiry-info-edit-button"
          >
            <mat-icon>edit</mat-icon>
            <span i18n>Bearbeiten</span>
          </button>

          @if (inquiry.locations === null || inquiry.locations.length < 1) {
            <button
              [disabled]="isLoading$()"
              (click)="requestLocation()"
              color="primary"
              mat-flat-button
              class="inquiry-info-edit-button"
            >
              <mat-icon>my_location</mat-icon>
              <span i18n>Standort abfragen</span>
            </button>
          }
        </div>
      }
    }
  </div>
</div>
