import { SubscriptionService as SubscriptionApi } from 'projects/helper-client/src/api/gen';
import { map, Observable } from 'rxjs';
import { Subscription } from '../../model/plan/subscription';
import { Injectable } from '@angular/core';
import { SubscriptionEndTime } from '../../model/plan/subscription-end-time';
import { LatestSubscriptionPeriod } from '../../model/plan/latest-subscription-period';

@Injectable()
export class SubscriptionService {
  constructor(private readonly subscriptionService: SubscriptionApi) {}

  getSubscriptions(): Observable<Subscription[]> {
    return this.subscriptionService.subscriptionGet().pipe(
      map((x) => {
        return x.map((dto) => Subscription.fromDto(dto));
      }),
    );
  }

  getSubscriptionById(subscriptionId: string): Observable<Subscription> {
    return this.subscriptionService
      .subscriptionSubscriptionIdentifierGet(subscriptionId)
      .pipe(map((dto) => Subscription.fromDto(dto)));
  }

  getSubscriptionEndTime(
    subscriptionId: string,
  ): Observable<SubscriptionEndTime> {
    return this.subscriptionService
      .subscriptionSubscriptionIdentifierEndTimeGet(subscriptionId)
      .pipe(map((dto) => SubscriptionEndTime.fromDto(dto)));
  }

  getActiveSubscription(): Observable<Subscription> {
    return this.subscriptionService
      .subscriptionActiveGet()
      .pipe(map((dto) => Subscription.fromDto(dto)));
  }

  getLatestSubscription(): Observable<LatestSubscriptionPeriod> {
    return this.subscriptionService
      .subscriptionLatestPeriodGet()
      .pipe(map((dto) => LatestSubscriptionPeriod.fromDto(dto)));
  }

  cancelSubscription(subscriptionId: string): Observable<Subscription> {
    return this.subscriptionService
      .subscriptionSubscriptionIdentifierDelete(subscriptionId)
      .pipe(map((dto) => Subscription.fromDto(dto)));
  }
}
