import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class SalesforceTenantResolverService {
  resolveTenant(orgId: string, userId: string): Observable<string> {
    // TODO: Fetch from backend
    console.log(`${orgId} - ${userId}`);
    return of('madtech');
  }
}
