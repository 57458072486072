import { CurrentSubscriptionPeriodDto } from '../../../api/gen';

export class LatestSubscriptionPeriod {
  id?: string;
  externalIdentifier?: string | null;
  startedAt?: string | null;
  endedAt?: string | null;
  cancelledAt?: string | null;

  static fromDto(dto: CurrentSubscriptionPeriodDto): LatestSubscriptionPeriod {
    return {
      id: dto.id,
      externalIdentifier: dto.externalIdentifier,
      startedAt: dto.startedAt,
      endedAt: dto.endedAt,
      cancelledAt: dto.cancelledAt,
    } as LatestSubscriptionPeriod;
  }
}
