import { Injectable } from '@angular/core';

export type StorageKey =
  | 'audioInputId'
  | 'audioOutputId'
  | 'videoInputId'
  | 'micState'
  | 'camState'
  | 'localstorageTest';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  // Some browsers block the local storage in various cases. Example:
  // Private/Incognito Mode (especially safari)
  //  Blocked Cookies/Storage in Browser Settings
  private readonly localStorageAvailable: boolean = true;

  constructor() {
    // Unfortunately, there is no other way to do this.
    try {
      this.set('localstorageTest', 'test');
      this.remove('localstorageTest');
      this.localStorageAvailable = true;
    } catch {
      this.localStorageAvailable = false;
    }
  }

  get(key: StorageKey): string {
    return this.localStorageAvailable
      ? localStorage.getItem(this.formatAppStorageKey(key))
      : null;
  }

  set(key: StorageKey, value: string) {
    if (value && value !== 'null' && this.localStorageAvailable) {
      localStorage.setItem(this.formatAppStorageKey(key), value);
    }
  }

  remove(key: StorageKey) {
    if (this.localStorageAvailable) {
      localStorage.removeItem(this.formatAppStorageKey(key));
    }
  }

  private formatAppStorageKey(key: StorageKey) {
    return `madtech.aidar.${key}`;
  }
}
