import { Injectable } from '@angular/core';
import {
  InvitationStatusService as InvitationApi,
  SmsStatus,
} from 'projects/helper-client/src/api/gen';
import { filter, Observable, Subject } from 'rxjs';
import {
  AppointmentMessageType,
  AppointmentMessagingService,
} from '../../services/signaling/messaging/appointment-messaging.service';

@Injectable()
export class InvitationStatusService {
  private readonly currentStatus$ = new Subject<InvitationStatus>();

  constructor(
    private readonly appointmentMessagingService: AppointmentMessagingService,
    private readonly invitationApi: InvitationApi,
  ) {}

  subscribeToInvitationStatus(inquiryId: string): Observable<InvitationStatus> {
    this.invitationApi
      .notificationStatusInquiryIdGet(inquiryId)
      .pipe(filter((x) => !!x))
      .subscribe((value) => {
        this.publishStatus(value.invitationStatus);
        this.appointmentMessagingService
          .onMessage(AppointmentMessageType.Invitation_Status_Changed)
          .subscribe((data: SmsStatus) => {
            this.publishStatus(data);
          });
      });
    return this.currentStatus$.asObservable();
  }

  private publishStatus(status: SmsStatus): void {
    switch (status) {
      case SmsStatus.Delivered:
        this.currentStatus$.next(InvitationStatus.Delivered);
        break;
      case SmsStatus.Clicked:
        this.currentStatus$.next(InvitationStatus.Clicked);
        break;
      case SmsStatus.Error:
        this.currentStatus$.next(InvitationStatus.Error);
        break;
      case SmsStatus.Sent:
        this.currentStatus$.next(InvitationStatus.Sent);
        break;
      default:
        break;
    }
  }
}

export enum InvitationStatus {
  Sent,
  Delivered,
  Clicked,
  Error,
}
