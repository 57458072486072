import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InquiryDetailsComponent } from './inquiry-details/inquiry-details.component';
import { SchedulingComponent } from './scheduling.component';
import { NavigateToAttachmentComponent } from './inquiry-details/attachments/navigate-to-attachment/navigate-to-attachment.component';

const routes: Routes = [
  { path: '', component: SchedulingComponent },
  { path: 'details', component: InquiryDetailsComponent },
  { path: 'navigate-to-attachment', component: NavigateToAttachmentComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SchedulingRoutingModule {}
