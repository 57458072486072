import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, first, Observable, Subject } from 'rxjs';
import { AnnotateImageComponent } from '../../../../annotations/annotate-image/annotate-image.component';
import {
  DrawEvent,
  Mode,
} from '../../../../annotations/drawing-canvas/drawing-canvas.component';
import { CanvasSyncService } from '../../../../annotations/services/canvas-sync.service';
import {
  AppointmentAttachmentsService,
  CallAttachment,
} from '../../../services/appointment-attachments.service';
import { CallCtrlService } from '../../../services/call-ctrl.service';
import { VideoChatService } from '../../../services/videochat.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  OcrResultDialogComponent,
  OcrResultDialogData,
} from '../../../dialogs/ocr-result-dialog/ocr-result-dialog.component';
import { AttachmentCvService } from '../../../../services/api/attachment-cv.service';
import { FoundText } from '../../../../model/attachment/found-text';

@Component({
  selector: 'app-present-image',
  templateUrl: './present-image.component.html',
  styleUrls: ['./present-image.component.scss'],
  providers: [CanvasSyncService, AttachmentCvService],
})
export class PresentImageComponent implements OnInit, OnDestroy {
  @ViewChild('annotateImage') annotateImage: AnnotateImageComponent;
  @Input() public currentImage: CallAttachment;
  @Input() public mode$: Observable<Mode>;
  @Input() public allowedControls: AllowedControls;

  @Output() public closeAttachment = new EventEmitter<void>();
  @Output() modeChanged = new EventEmitter<Mode>();
  protected Mode = Mode;
  protected isAttachmentPresented: Observable<boolean>;
  protected readTextWithOcrDisabled: BehaviorSubject<boolean>;
  private currentMode = Mode.Idle;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    protected readonly attachmentService: AppointmentAttachmentsService,
    protected readonly videoChatService: VideoChatService,
    protected readonly callCtrlService: CallCtrlService,
    protected readonly canvasSyncService: CanvasSyncService,
    private readonly attachmentCvService: AttachmentCvService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) {
    this.readTextWithOcrDisabled = new BehaviorSubject(false);
    this.isAttachmentPresented = this.attachmentService
      .onCurrentAttachment()
      .pipe(map((x) => x?.isInPresentationMode));
  }

  ngOnInit(): void {
    this.mode$.pipe(takeUntil(this.unsubscribe$)).subscribe((newMode) => {
      this.currentMode = newMode;
    });

    this.callCtrlService.modeChannel$
      .asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mode) => {
        if (mode === Mode.Annotate_Attachment) {
          this.annotateImage.startAnnotating();
        }
      });

    // Hack, as we cannot be sure if the view is rendered
    // This also harms the dependency inversion principle
    setTimeout(() => {
      this.canvasSyncService.referenceElementChanged(
        this.annotateImage.editArea.nativeElement,
      );
    }, 1000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.attachmentService
      .onCurrentAttachment()
      .pipe(first())
      .subscribe((x) => {
        if (x) {
          this.attachmentService.stopPresentingAttachment().subscribe();
        }
        this.callCtrlService.modeChannel$.pipe(first()).subscribe((mode) => {
          if (mode === Mode.Annotate_Attachment) {
            this.callCtrlService.modeChannel$.next(Mode.Idle);
          }
          this.closeAttachment.emit();
        });
      });
  }

  protected drawOnVideo(data: DrawEvent) {
    this.videoChatService.drawOnVideo(data);
  }

  protected finishAnnotating(shouldReload: boolean) {
    this.callCtrlService.modeChannel$.next(Mode.Idle);
    if (shouldReload) {
      this.attachmentService
        .onCurrentAttachment()
        .pipe(first())
        .subscribe((x) => {
          if (x.isInPresentationMode) {
            this.attachmentService.startPresentingAttachment().subscribe();
          }
        });
    }
  }

  protected onPresentAttachment() {
    this.attachmentService
      .onCurrentAttachment()
      .pipe(
        first(),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        if (x.isInPresentationMode) {
          this.attachmentService.stopPresentingAttachment().subscribe();
        } else {
          this.attachmentService.startPresentingAttachment().subscribe();
        }
      });
  }

  protected onStartAnnotating() {
    this.modeChanged.emit(Mode.Annotate_Attachment);
  }

  protected onReadTextWithOcr() {
    this.readTextWithOcrDisabled.next(true);
    const currentAttachmentData =
      this.attachmentService.getCurrentAttachmentData();
    const currentAttachment =
      currentAttachmentData.attachments[currentAttachmentData.currentIndex];

    if (currentAttachment) {
      this.attachmentCvService
        .readTextFromAttachment(currentAttachment.id)
        .subscribe({
          next: (foundTexts) => {
            this.showOcrResults(foundTexts);
          },
          error: () => {
            this.showOcrError(
              $localize`Text konnte nicht von Anhang gelesen werden.`,
            );
          },
        });
    } else {
      this.showOcrError($localize`Kein Anhang zum Auslesen gefunden.`);
    }
  }

  private showOcrResults(foundTexts: FoundText[]) {
    const currentAttachmentData =
      this.attachmentService.getCurrentAttachmentData();
    const currentAttachment =
      currentAttachmentData.attachments[currentAttachmentData.currentIndex];
    const dialogRef = this.dialog.open(OcrResultDialogComponent, {
      data: {
        foundTexts: foundTexts,
        attachmentId: currentAttachment.id,
      } as OcrResultDialogData,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.readTextWithOcrDisabled.next(false);
    });
  }

  private showOcrError(errorText: string) {
    this.snackBar.open(errorText, null, { duration: 5000 });
    this.readTextWithOcrDisabled.next(false);
  }
}

export interface AllowedControls {
  edit: boolean;
  present: boolean;
  ocr: boolean;
}
